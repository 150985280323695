import React from 'react'
import { graphql, Link } from 'gatsby'


export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
            }
            html
        }
    }
`

const blogTemplate = (props) => {
  
    return (
      <div className="container page">

        <div className="blog-post">
          <h1 className="major">{props.data.markdownRemark.frontmatter.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
          ></div>
          
          <div className="button" id="page-nav-button">
            <Link 
              to="/"
              state={{
                isArticleVisible:true,
                article:"blog",
                tabIndex:"0"
              }}
            >
              back
            </Link>
          </div>
          
        </div>
      </div>
    )
    }

export default blogTemplate
